var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vps-order page"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")]),(_vm.loading && !_vm.list.length)?_c('div',{staticClass:"vps-order__loading"},[_c('base-loader')],1):(_vm.list.length)?_c('div',{staticClass:"vps-order__content"},[_c('page-block',{attrs:{"title":_vm.$t('choise'),"size":"bigger"}},[_c('pricelist-block',{attrs:{"list":_vm.list},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('pricelist-card',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#el',
              duration: 1900,
              lazy: true,
              easing: 'ease',
              offset: -100,
            }),expression:"{\n              el: '#el',\n              duration: 1900,\n              lazy: true,\n              easing: 'ease',\n              offset: -100,\n            }"}],attrs:{"id":item.id,"title":item.name,"list":_vm.getSpecs(item),"price":item.cost,"discount":+item.price.period[0].discount_amount > 0 ? item.price.period[0].full_cost : null,"active":_vm.current && _vm.current.id === item.id},on:{"click":function($event){return _vm.routeTo(item)}}})]}}])})],1),_c('div',{attrs:{"id":"el"}},[_c('router-view',{on:{"change-period":function($event){return _vm.changePeriod($event)}}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }