<template>
  <div class="vps-order page">
    <h1 class="page-title">
      {{ $t('title') }}
    </h1>
    <!--    <transition name="fade" mode="out-in">-->
    <div v-if="loading && !list.length" class="vps-order__loading">
      <base-loader />
    </div>
    <div v-else-if="list.length" class="vps-order__content">
      <page-block :title="$t('choise')" size="bigger">
        <pricelist-block :list="list">
          <template v-slot:item="{ item }">
            <pricelist-card
              :id="item.id"
              v-scroll-to="{
                el: '#el',
                duration: 1900,
                lazy: true,
                easing: 'ease',
                offset: -100,
              }"
              :title="item.name"
              :list="getSpecs(item)"
              :price="item.cost"
              :discount="
                +item.price.period[0].discount_amount > 0 ? item.price.period[0].full_cost : null
              "
              :active="current && current.id === item.id"
              @click="routeTo(item)"
            />
          </template>
        </pricelist-block>
      </page-block>
      <!--        <transition name="fade" mode="out-in">-->
      <div id="el">
        <router-view @change-period="changePeriod($event)"> </router-view>
      </div>
      <!--        </transition>-->
    </div>
    <!--    </transition>-->
  </div>
</template>

<script>
import PricelistBlock from '@/components/Configurator/components/PricelistBlock';
import PricelistCard from '@/components/Configurator/components/PricelistCard';
import showErrorModal from '@/mixins/showErrorModal';
import { BillMgrPeriods } from '@/models/BillMgr/periods';
import gtmMixin from '@/mixins/gtmMixin';
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);
export default {
  name: 'VpsOrder',
  components: { PricelistBlock, PricelistCard },
  mixins: [showErrorModal, gtmMixin],
  data() {
    return {
      configData: null,
      period: 1,
      currentPeriod: 1,
      cost: 0,
      costDetails: null,
      isCalculating: false,
      isSendingToBasket: false,
      isSendingToPay: false,
      // currentUrlPeriod: 1,
    };
  },
  computed: {
    list() {
      this.addDataLayerPriceData(this.$store.state.moduleVPS.moduleVpsOrder.list, 'vds');
      return this.$store.state.moduleVPS.moduleVpsOrder.list;
    },
    loading() {
      return this.$store.state.moduleVPS.moduleVpsOrder.loading;
    },
    current() {
      return this.$store.getters['moduleVPS/moduleVpsOrder/current'] || this.currentItem;
    },
    getCurrentPeriod() {
      return this.currentPeriod === this.period ? this.period : this.currentPeriod;
    },
    id() {
      if (this.$route.path === '/vds/order/list/basic') {
        return;
      } else {
        return this.$route.params.id ? +this.$route.params.id : this.current.id;
      }
    },
    currentItem() {
      return this.list.find(i => i.id === +this.id);
    },
    priceByPeriod() {
      return this.current.priceByPeriod.map(i => ({
        label: this.$tc(`period.${BillMgrPeriods[i.period]}_c`, i.period),
        value: i.period,
      }));
    },
    periodValue() {
      return this.priceByPeriod.find(i => i.value === this.period);
    },
  },
  mounted() {
    // this.currentUrlPeriod = +this.$route.params.period;
    if (!this.current) {
      // console.log(' mounted fetchPricelist');
      return this.fetchPricelist();
    }
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleVPS/moduleVpsOrder/reset');
    next();
  },
  methods: {
    routeTo(item) {
      this.clickToGtm(item, 'vds');
      this.viewDetailGtm(item, 'vds');
      this.setCurrent(item);
      this.fetchParams(item, this.period);
      this.updateConfig();
      this.$router
        .push({
          name: 'vpsOrderDetails',
          params: { id: item.id, period: this.period },
        })
        .catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            this.showErrorModal(err);
          }
        });
    },
    // api and logic methods
    fetchPricelist() {
      // console.log('compute fetchPricelist');
      this.$store.dispatch('moduleVPS/moduleVpsOrder/fetchPricelist');
    },
    fetchCalc(data) {
      return this.$store.dispatch('moduleVPS/moduleVpsOrder/fetchCalc', data);
    },
    setCurrent(item) {
      this.$store.dispatch('moduleVPS/moduleVpsOrder/setCurrent', item.id);
    },
    fetchParams(item, period) {
      const params = {};
      if (item) params.id = item.id;
      if (period) params.period = period;
      return this.$store.dispatch('moduleVPS/moduleVpsOrder/fetchParams', params);
    },
    updateConfig(data) {
      this.configData = { ...data };
    },
    getSpecs(item) {
      const list = [];
      for (let prop of Object.keys(item.specs)) {
        list.push(
          this.$t(`specs.${prop}`, {
            n: item.specs[prop],
            i: item.name.includes('Ultra') ? 'NVME' : 'SSD',
          })
        );
      }
      return list;
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
    changePeriod(period) {
      this.currentPeriod = +period;
      this.$router.push({
        name: 'vpsOrderDetails',
        params: { id: this.current.id, period: period },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Создание VPS сервера",
    "choise": "Выберите тариф",
    "additional": "Дополнительно",
    "summary": "Итого",
    "payfor": "при оплате за",
    "pay": "Оплатить",
    "order": "В корзину",
    "specs": {
      "disc": "{n} ГБ {i}",
      "mem": "{n} ГБ RAM",
      "ncpu": "{n} CPU"
    },
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Сервер успешно создан, {num} списано с лицевого счета"
    },
    "needbalance": "Необходимо пополнить баланс"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-order {
  &__loading {
    flexy(center, center);
    flex: 1 1 100%;
  }
  &__sum {
    flex: 0 0 100%;

    +breakpoint(ms-and-up) {
      flex: 0 0 auto;
      margin-right: 0.5rem;
    }
  }
  &__price {
    flexy(flex-start, center, wrap);

    &-text {
      margin-right: 0.5rem;

      +breakpoint(sm-and-up) {
        font-size: $font-size-bigger;
      }
    }
  }
  &__period {
    flex: 0 0 8.3rem;

    +breakpoint(sm-and-up) {
      flex: 0 0 10rem;
    }
  }

  &__actions {
    margin: 0.75rem -0.75rem -0.75rem;
    flexy(flex-start, center, wrap);
  }

  &__btn {
    margin: 0.75rem;

    +breakpoint(xs-only) {
      flex: 1 1 100%;
    }
  }
}
</style>
